
.justifyCenter {
  justify-content: center;
}

.justifySpaceBetween {
  justify-content: space-between;
}

.alignCenter {
  align-items: center;
}
.textCenter{
  text-align: center;
}

.flexDir{
  flex-direction: column;
}

.flex_wrap{
  flex-wrap: wrap;
}

#imageUpload{
  display: none;
}
.select_file {
    /* border: 1px solid red; */
    padding: 20px 0;
  }
  
  .mainBox>div {
    gap: 10px;
  }
  
  .mainBox {
    padding: 30px 50px 50px 50px;
    border-radius: 10px;
    height: auto;
  }
  
  .imageUpload {
    margin-top: 30px;
    position: relative;
  }
  
  
  .imageUploadInput {
    position: relative;
    width: 100%;
    /* min-height: 100px; */
    height: auto;
    cursor: pointer;
    border: 2px dotted #0000005e;
    border-radius: 10px;
  }
  .imageUploadInput:after{
    content: "Drop Your Files Here";
    position: absolute;
    color: var(--textCol);
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
  }
  .imageUploadInput::file-selector-button {
    background: rgba(151, 151, 151, 0.036);
    border: none;
    cursor: pointer;
    width: 100%;
    padding: 100px 0;
    opacity: 0;
  }
  
  .afterCompress {
    padding: 50px 0 0 0;
  }
  
  .afterCompress>div {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 0px 00px 0px 0px;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .fileName {
    border-bottom: 1px solid #11111139;
    padding: 20px 20px;
  }
  
  .preview {
    /* border: 1px solid red; */
    padding: 30px 50px 50px 50px;
    position: relative;
    overflow: hidden;
    /* height: 400px; */
  }
  
  .imageSizeControll>input {
    width: 100%;
    max-width: 500px;
  }
  
  .imageSizeControll {
    padding: 20px 50px;
    gap: 10px;
  }
  
  .image img{
    /* width: 100%; */
    text-align: center;
    margin: 0 auto;
  
  }
  .image {
    width: 100%;
    height: 100%;
    /* position: absolute; */
    top: 0;
    left: 0px;
    text-align: center;
  }
  
  .resizeAbleImage {
    resize: horizontal;
    width: 50%;
    z-index: 999999999999999;
    overflow: hidden;
    /* right: 0; */
  }
  
  .listOfImg{
    /* border: 1px solid red; */
    border-radius: 5px;
    /* position: absolute; */
    width: 100%;
    padding: 20px 20px;
    height: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: rgb(237, 237, 237);
    gap: 10px;
  }
  
  
  .containImg{
    width: 200px;
    height: 150px;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    border: 4px solid transparent;
    transition: .5s;
  }
  .card_active{
    border: 4px solid var(--buttonDef);
  }
  .containImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .containImg >div{
    padding: 10px 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.33);
  }
  
  .containImg p{
    background: #00000067;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
  
  
    margin-bottom: 10px;
    gap: 10px;
    margin-top: 55px;
    
  }
  
  .remove_card:hover{
    background: #ffffff8c;
  }
  .remove_card{
    position: absolute;
    z-index: 999;
    padding: 3px 5px;
    right: 10px;
    top: 10px;
    font-size: 16px;
    font-weight: 900;
    background: var(--white);
    border: none;
    color: var(--black);
  }
  .containButton button a{
    color: var(--black);
    width: 100%;
  }
  .containButton button:hover{
    background: #ffffff8c;
  }
  .containButton button{
    width: 100%;
    background: var(--white);
    border: 1px solid transparent;
  }
  
  .fileNameCard p{
    margin-top: 0 !important;
    font-size: 12px;
  }
  .fileNameCard{
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    z-index: 9999;
  }
  
  .downloadAllImages{
    margin-top: 20px;
  }
  
  .mainCardComp{
    position: relative;
  }
  
  
  